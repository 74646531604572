import * as React from "react";
import { Col, Row } from 'reactstrap';
import { map } from 'lodash-es';
import Page from '../../components/Page/page';
import NewsCard from '../../components/NewsCard/news-card';
import { graphql, useStaticQuery } from "gatsby";

export const News = ({ limit = 1000 }) => {
    const result = useStaticQuery(graphql`
    {
        site {
            siteMetadata {
                newsArticles {
                    title,
                    icon,
                    description,
                    fullArticleUrl,
                    date
                }
            }
        }
    }
    `);

    const newsArticles = result.site?.siteMetadata?.newsArticles;
    return (
        <Row>
            {map(newsArticles, (newsArticle, index) => {
                if (limit === undefined || (limit !== undefined && limit > index)) {
                    return (
                        <Col key={index} xs="12" md="6" lg="4">
                            <NewsCard className={`mt-1 animate__animated animate__fadeInUp animate__faster animate__delay-${index * 250}ms`} key={newsArticle.title} {...newsArticle} />
                        </Col>
                    );
                }
            })
            }
        </Row>
    );
};


const NewsPage = ({ limit, location }) => {
    return (
        <Page animateHeader titleClassName="text-center" title="Latest News" seoTitle="Latest News" location={location}>
            <News limit={limit} />
        </Page>
    );
};

export default NewsPage;
