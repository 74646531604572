import * as React from "react"
import { Link } from 'gatsby';
import { truncate } from 'lodash-es';
import { Button, Card, CardBody, CardImg, CardSubtitle, CardTitle } from 'reactstrap';

const NewsCard = ({ className, date, description, fullArticleUrl, title, icon }) => {
    return (
        <div className={className}>
            <Card color="dark" inverse>
                <CardImg src={icon} top width="100%" />
                <CardBody>
                    <CardTitle tag="h4">
                        {title}
                    </CardTitle>
                    <CardSubtitle tag="h5">
                        {date}
                    </CardSubtitle>
                    {truncate(description, { length: 200 })}
                    <div className="mt-3">
                        <Link to={fullArticleUrl}>
                            <Button color="primary" block>
                                View More
                            </Button>
                        </Link>
                    </div>
                </CardBody>
            </Card>
        </div>
    );
};

export default NewsCard;